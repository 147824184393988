import React, { useEffect, useRef, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {axiosInstance} from "../api";
import {getIDTokenFromCookie} from "../authorization/js/auth-utils";
import { PageLoader, AlertComponent } from './presentational';
import { useHistory } from "react-router-dom";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  root: {
    padding: theme.spacing(1.5),
  },
}))(TableCell);

// each row should have alternating color
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    width: "800px",
  },
  gridContainer: {
    padding: "5%",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)",
  },
  reportNameCell: {
    width: "140px",
  },
  reportUrlCell: {
    width: "300px",
  },
  actionCell: {
    width: "60px",
    textAlign: "center",
  },
  borderCell: {
    borderRight: "1px solid black",
  },
  centerText: {
    textAlign: "center",
  },
  formInput: {
    marginBottom: theme.spacing(3),
  },
  buttonMargin: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

// filter for autocomplete
const filter = createFilterOptions();

export default function CreateDashboardReport() {
  const history = useHistory();
  const classes = useStyles();
  // for urls and report names
  const [url, setUrl] = useState("");
  const [reportName, setReportName] = useState("");
  const [urlList, setUrlList] = useState([]);
  const [banner, setBanner] = useState("");
  const [bannerError, setBannerError] = useState(false);
  let [alertProps, setAlertProps] = useState(null);
  let [pageLoaderProps, setPageLoaderProps] = useState(true);
  // for title
  const [title, setTitle] = useState(null);
  const [productList, setProductList] = useState([]);

  // for form validation
  const [titleInputError, setTitleInputError] = useState(false);
  const [urlListError, setUrlListError] = useState(false);

  // used to focus report name after submit
  let reportNameInput = useRef(null);
  // fetch all report suggestions
  useEffect(() => {
    const idtoken = getIDTokenFromCookie();
    axiosInstance
      .get("/dashboardread",{headers: {"auth_id":idtoken}})
      .then((response) => {
        if (response.data !== "False") {
          setProductList(response.data);
        } else {
          setProductList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertProps({ show: true, alertMessage: 'Failed to load usage dashboard. Try again later! '});
      }).finally(() => {
      // turn off page loading after api call.
      setPageLoaderProps(false);
    });;
  }, []);

  // handle submission of entire form (url and title)
  const handleFormSubmit = () => {
    const idtoken = getIDTokenFromCookie();
    if (urlList.length === 0) {
      setUrlListError(true);
      return;
    }
    if (title == null) {
      setTitleInputError(true);
      return;
    }
    setTitleInputError(false);
    setUrlListError(false);
    setPageLoaderProps(true);
    let obj = {
      product: title.product,
      urls: urlList,
    };
    axiosInstance
      .post("/dashboardwrite", obj,{headers: {"auth_id":idtoken}})
      .then((response) => {
          setAlertProps({ show: true,severity:"success", alertMessage: ' Successfuly created Usage dashboard Report'});
          setUrlList([]);
          setTitle(null);
          //history.push('/dashboard');
      })
      .catch((error) => {
        console.log(error)
        setAlertProps({ show: true, alertMessage: 'Failed to create usage dashboard. Try again later! '});
      }).finally(() => {
      // turn off page loading after api call.
      setPageLoaderProps(false);
    });
  };

  // handles submission of url + report name
  const handleUrlSubmit = () => {
    if (url === "" || reportName === "") {
      setUrlListError(true);
      return;
    }

    setUrlList((prevState) => [
      ...prevState,
      { url: url, reportName: reportName },
    ]);

    setUrl("");
    setReportName("");
    reportNameInput.current.focus();
  };

  // allow users to delete rows
  const handleUrlRemove = (id) => {
    const newList = urlList.filter((item) => item.url + item.reportName !== id);
    setUrlList(newList);
  };

  return (
    <Grid container className={classes.gridContainer} justifyContent="center">
      { pageLoaderProps && <PageLoader pageLoaderProps={pageLoaderProps} />}
      { alertProps && <AlertComponent alertProps={alertProps}/> }

      <Grid item xs={12}>
        <Box mb={4}>
          <Typography variant="h4" className={classes.centerText}>
            Create Usage Dashboard Report
          </Typography>
        </Box>
      </Grid>

      {/* product title */}
      <Grid container item xs={12} justifyContent="center">
        {/* <TextField
          error={titleInputError ? true : false}
          helperText={titleInputError ? "Title can't be blank!" : ""}
          required
          id="title"
          label="Enter Product Title"
          placeholder="Enter product name"
          value={title}
          onChange={(e) => {
            setTitleInputError(false);
            setTitle(e.target.value);
          }}
          className={classes.formInput}
        /> */}
        <Autocomplete
          value={title}
          onChange={(e, newValue) => {
            if (typeof newValue === "string") {
              setTitle({ product: newValue });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setTitle({ product: newValue.inputValue });
            } else {
              setTitle(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== "") {
              filtered.push({
                inputValue: params.inputValue,
                product: `⸻⸻⸻⸻⸻⸻\n Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="productTitle"
          options={productList}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.product;
          }}
          renderOption={(option) => option.product}
          style={{ width: 300 }}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label="Product title"
              variant="outlined"
              error={titleInputError ? true : false}
              helperText={titleInputError ? "Title can't be blank!" : ""}
              className={classes.formInput}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" className={classes.centerText}>
          Enter report names and urls:
        </Typography>
      </Grid>
      <Grid item>
        <TableContainer className={classes.table} component={Paper}>
          <Table aria-label="customized table">
            {/* table header elements */}
            <TableHead>
              <TableRow>
                <StyledTableCell
                  className={classes.reportNameCell}
                  style={{ borderRight: "1px solid white" }}
                >
                  Report Name
                </StyledTableCell>
                <StyledTableCell
                  className={classes.reportUrlCell}
                  style={{ borderRight: "1px solid white" }}
                >
                  Report URL
                </StyledTableCell>
                <StyledTableCell className={classes.actionCell}>
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>

            {/* url list body */}
            <TableBody>
              {urlList.map((row) => (
                <StyledTableRow key={row.url + row.reportName}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={`${classes.borderCell} ${classes.reportNameCell}`}
                  >
                    {row.reportName}
                  </StyledTableCell>

                  <StyledTableCell
                    className={`${classes.borderCell} ${classes.reportUrlCell}`}
                  >
                    <TextField fullWidth value={row.url} />
                  </StyledTableCell>

                  <StyledTableCell className={classes.actionCell}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleUrlRemove(row.url + row.reportName)}
                    >
                      Delete
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              {/* this is the input row (last row) */}
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  className={`${classes.borderCell} ${classes.reportNameCell}`}
                >
                  <TextField
                    error={urlListError ? true : false}
                    helperText={
                      urlListError
                        ? "Please add at least one report/url entry!"
                        : ""
                    }
                    id="reportName"
                    label="Report Name"
                    inputRef={reportNameInput}
                    value={reportName}
                    onChange={(e) => {
                      setUrlListError(false);
                      setReportName(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleUrlSubmit();
                    }}
                  />
                </StyledTableCell>

                <StyledTableCell
                  className={classes.borderCell}
                  style={{ maxWidth: "200px" }}
                >
                  <TextField
                    id="urlInput"
                    label="Report URL"
                    fullWidth
                    value={url}
                    onChange={(e) => {
                      setUrlListError(false);
                      setUrl(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleUrlSubmit();
                    }}
                  />
                </StyledTableCell>

                <StyledTableCell className={classes.actionCell}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setUrlListError(false);
                      handleUrlSubmit();
                    }}
                  >
                    Add
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* submit button */}
      <Grid  className={classes.buttonMargin}  justifyContent = "center" item  container  spacing={2}>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFormSubmit}
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
              variant="contained"
              color="primary"
              onClick={()=>history.push("/dashboard")}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
