import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Button, Divider, Grid, Tab} from "@material-ui/core";
import { TabContext, TabPanel, TabList} from "@material-ui/lab";
import axios from 'axios';
import Box from '@material-ui/core/Box';
import { makeStyles } from "@material-ui/core/styles";
import WindchillCard from "./WindchillCard";
import { TableCell, TableRow} from "@material-ui/core";
import {getUserEmail, getIDTokenFromCookie, isAdmin} from "../authorization/js/auth-utils";
import { PageLoader, AlertComponent } from './presentational';
import {wocAxiosInstance} from "../api";


const style = makeStyles({
    createButton: {
        margin: '12px',
        float: 'right',
    },
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    gridBox: {
        width: '40%',
        margin: 10,
    },
    cardHeaders:{
        marginTop: 5,
        fontSize: "h6.fontSize",
    },
    cardTable: {
        backgroundColor: "#3f51b5",
    },
    formLink:{
        textDecoration: 'none',
        color: 'white'
    }
});

export default function WindchillDashboard(){
    const [tableInstanceData, setTableInstanceData] = useState([]);
    const [instances, setInstances] = useState([]);
    const [pendingInstances, setPendingInstances] = useState([]);
    const [username, setUsername] = useState("");
    const classes = style();
    let [alertProps, setAlertProps] = useState(null);
    let [pageLoaderProps, setPageLoaderProps] = useState(true);

    useEffect(() => {
        setUsername(getUserEmail().split("@")[0].trim().normalize());
    }, []);

    function parseInstanceData(tableInstanceData) {
        let instanceData = [];
        let instanceMap = new Map();

        tableInstanceData.forEach(instance => {
            // instance.dnsName
            if (instanceMap.has(instance.dnsName)) {
                const details = instanceMap.get(instance.dnsName);
                if (!details.APP) {
                    // missing APP entry
                    details["APP"] = {
                        instanceName: instance.instanceName,
                        instanceID: instance.instanceID,
                        instanceType: instance.instanceType,
                        privateIP: instance.privateIP,
                    }
                } else {
                    // missing DB entry
                    details["DB"] = {
                        instanceName: instance.instanceName,
                        instanceID: instance.instanceID,
                        instanceType: instance.instanceType,
                        privateIP: instance.privateIP,
                    }
                }
            }
            else {
                if (instance.instanceName.toString().indexOf("APP") >= 0) {
                    instanceMap.set(instance.dnsName, {
                        owner: instance.owner,
                        APP: {
                            instanceName: instance.instanceName,
                            instanceID: instance.instanceID,
                            instanceType: instance.instanceType,
                            privateIP: instance.privateIP,
                        }
                    });
                } else {
                    instanceMap.set(instance.dnsName, {
                        owner: instance.owner,
                        DB: {
                            instanceName: instance.instanceName,
                            instanceID: instance.instanceID,
                            instanceType: instance.instanceType,
                            privateIP: instance.privateIP,
                        }
                    });
                }
            }

        });

        for(let [key, value] of instanceMap){
            instanceData.push([key, value.owner, { APP: value.APP, DB: value.DB } ]);
        }
        return instanceData;
    }

    function parsePendingInstanceData(pendingInstances) {
        // TODO: Link to Stepfunction and poll
        let pendingInstanceData = [];
        pendingInstances.forEach(pendingInstance =>
            pendingInstanceData.push([pendingInstance.dnsName, pendingInstance.owner, pendingInstance.status])
        )
        return pendingInstanceData;
    }

    useEffect(() => {
        let idtoken = getIDTokenFromCookie();
        wocAxiosInstance.get('/windchill/info',{headers: {'auth_id' : idtoken}})
            .then(function (response) {
                setInstances(response.data);
                setTableInstanceData(response.data);
            })
            .catch(function (error) {
                console.log(error);
                // idtoken check to be removed once edge lambda implementation for authorization is done.
                if (idtoken === undefined) {
                    setPageLoaderProps(true);
                } else {
                    setAlertProps({show: true, alertMessage: 'Failed to load info. Try again later! '});
                }
            })
            .then(function () {
            });

        wocAxiosInstance.get('/windchill/statusTable',{headers: {'auth_id' : idtoken}})
            .then(function (response) {
                setPendingInstances(response.data)
            })
            .catch(function (error) {
                console.log(error);
                // idtoken check to be removed once edge lambda implementation for authorization is done.
                if (idtoken === undefined) {
                    setPageLoaderProps(true);
                } else {
                    setAlertProps({show: true, alertMessage: 'Failed to load status table. Try again later! '});
                }
            })
            .then(function () {
            }).finally(() => {
            // turn off page loading after api call.
            setPageLoaderProps(false);
        });;
    }, []);

/*    async function handleDelete(dnsName) {
        const payload =  {"dnsName":dnsName, "email":""};
        console.log(payload);
        window.open(window.location.href + '/delete','_blank');
        // TODO: GET EMAIL of instance SSO
    }*/

    const instanceColumns = [
        {
            name: "Environment", 
            options: {
                sort: false,
            }
        }, {
            name: "Owner",
            options: {
                sort: false,
            }
        },
        {
            name: "Instance details", // DO NOT DISPLAY
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                display: false,
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                viewColumns: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const rowIndex = (tableMeta.rowIndex)*2;
                    const data = {
                        instanceids:[tableInstanceData[rowIndex+1].instanceID.trim().normalize(),tableInstanceData[rowIndex].instanceID.trim().normalize()],
                        dnsName : tableInstanceData[rowIndex].dnsName.trim().normalize()
                    }
                    const owner = tableInstanceData[rowIndex].owner.trim().normalize();
                    const dnsName = tableInstanceData[rowIndex].dnsName.trim().normalize();
                    return(
                        /*<Button color="primary" variant="contained"  disabled =  {(owner === username || isAdmin()) ? false :true}>*/
                        <Button color="primary" variant="contained"  disabled =  {owner !== username}>
                            <Link to="/windchill/transfer"
                                  target="_blank"
                                  className={classes.formLink}
                                  //onClick={() => localStorage.setItem("dnsName", dnsName)}
                                  onClick={() => localStorage.setItem("data", JSON.stringify(data))}
                            >
                                TRANSFER
                            </Link>
                        </Button>
                    )
                }
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                viewColumns: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const rowIndex = (tableMeta.rowIndex)*2;
                    const owner = tableInstanceData[rowIndex].owner.trim().normalize();
                    const dnsName = tableInstanceData[rowIndex].dnsName.trim().normalize();
                    return(
                       /* <Button color="primary" variant="contained" disabled =  {(owner === username || isAdmin()) ? false :true}>*/
                        <Button color="primary" variant="contained" disabled =  {owner !== username}>
                            <Link to="/windchill/delete"
                                  target="_blank"
                                  className={classes.formLink}
                                  onClick={() => localStorage.setItem("dnsName", dnsName)}
                            >
                                DELETE
                            </Link>
                        </Button>
                    )
                }
            }
        }
    ];

    const pendingInstanceColumns = ["DNS Name", "Owner", "Status"];

    const instanceOptions = {
        download :'false',
        print:'false',
        searchPlaceholder: 'Search',
        customSearch: (searchQuery, currentRow, columns) => {
            let isFound = false;
            currentRow.forEach(col => {
                if (col) {
                    if (typeof col === "string" && col.toString().indexOf(searchQuery) >= 0) {
                        isFound = true;
                    }
                    if (typeof col === "object") {
                        for (const [key, value] of Object.entries(col.APP)) {
                            if (value.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                                isFound = true;
                            }
                        }
                        for (const [key, value] of Object.entries(col.DB)) {
                            if (value.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                                isFound = true;
                            }
                        }
                    }
                }
            });
            return isFound;
        },


        filterType: "dropdown",
        selectableRows: "none",
        expandableRows: true,
        expandableRowsOnClick: true,
        tableBodyMaxHeight: 'auto',
        renderExpandableRow: (rowData, rowMeta) => {
            const dataIndexDB = rowMeta.dataIndex * 2;
            const dataIndexAPP = (rowMeta.dataIndex * 2) + 1;
            return (
                <TableRow className={classes.cardTable}>
                    <TableCell colSpan={rowData.length + 1}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <WindchillCard instances={tableInstanceData} dataIndex={dataIndexAPP} type="APP"/>
                            </Grid>
                            <Grid item xs={6}>
                                <WindchillCard instances={tableInstanceData} dataIndex={dataIndexDB} type="DB"/>
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            );
        }
    };

    const pendingInstanceOptions = {
        download : 'false',
        print: 'false',
        filterType: "dropdown",
        selectableRows: "none",
        tableBodyMaxHeight: 'auto'
    }
    const [value, setValue] = React.useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const instanceData = parseInstanceData(tableInstanceData);
    const pendingInstanceData = parsePendingInstanceData(pendingInstances);

    return(
        <div>
            { pageLoaderProps && <PageLoader pageLoaderProps={pageLoaderProps} />}
            { alertProps && <AlertComponent alertProps={alertProps}/> }
        <Box>
            <Grid item>
                <Link to="/windchill/create" style={{ textDecoration: "none" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.createButton}
                    >
                        Create
                    </Button>
                </Link>
            </Grid>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Pending Windchill Instances" value="1" />
                        <Tab label="Created Windchill Instances" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <MUIDataTable
                        title={"Pending Windchill Instances"}
                        data={pendingInstanceData}
                        columns={pendingInstanceColumns}
                        options={pendingInstanceOptions}
                    />
                </TabPanel>
                <TabPanel value="2">
                    <MUIDataTable
                        title={"Created Windchill Instances"}
                        data={instanceData}
                        columns={instanceColumns}
                        options={instanceOptions}
                    />
                </TabPanel>
            </TabContext>
        </Box>
        </div>
    );
}