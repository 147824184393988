import React, { useRef, useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export function DeleteDialog(props) {
    const { show, deleteMessage, onClose, onDelete } = props;

    return (
        <Dialog
            open={ show }
            onClose={ onClose }
        >
            <DialogContent>
                <DialogContentText>
                    { deleteMessage }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus color="secondary" variant="contained" onClick={ onDelete }>
                    Delete
                </Button>
                <Button color="primary" variant="contained" onClick={ onClose }>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}