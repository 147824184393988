import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
  Fab,
  Tooltip,
  MuiThemeProvider,
  IconButton,
  Modal,
} from "@material-ui/core";
import { Delete as DeleteIcon, Close as CloseIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import MUIRichTextEditor from "mui-rte";
import {axiosInstance} from "../api";
import {getIDTokenFromCookie} from "../authorization/js/auth-utils";
import { PageLoader, AlertComponent, DeleteDialog } from './presentational';
import { showAnnouncementEditorModel } from "../theme";
import { isAdmin } from "../authorization/js/auth-utils"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width:800,
  height:800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  centerText: {
    textAlign: "center",
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  headerButton: {
    marginRight: theme.spacing(2),
  },
  headerHiddenButton: {
    marginLeft: theme.spacing(2),
  },
  hiddenButton: {
    visibility: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  cardShadow: {
    boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)",
  },
  cardContent: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  cardFooter: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontStyle: "italic",
    fontSize: "11px",
  },
  gridContent: {
    marginTop: theme.spacing(4),
  },
  dialogueTitle: {
    paddingBottom: theme.spacing(1),
  },
}));

const Home = () => {
  const classes = useStyles();
  const [alertProps, setAlertProps] = useState(null);
  const [pageLoaderProps, setPageLoaderProps] = useState(true);
  const [announcements, setAnnouncements] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [toDelete, setToDelete] = useState({});
  const isUserAdmin = isAdmin();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalAnnouncement, setModalAnnouncement] = useState(null);
  const handleModalOpen = (announcement) => {
    setModalAnnouncement(announcement);
    setModalOpen(true)
  };
  const handleModalClose = () => setModalOpen(false);


  // fetch all announcements at loading
  useEffect(() => {
    async function readAnnouncements() {
      const idtoken = getIDTokenFromCookie();
      let announcements;
      try {
        announcements = (await axiosInstance.get("/announcementsread",{headers: {"auth_id":idtoken}}))?.data ?? null;

        if (announcements) {
          announcements.sort((announcement1, announcement2) => {
            return announcement1.msgActivation < announcement2.msgActivation ? 1
                : announcement1.msgActivation > announcement2.msgActivation ? -1
                    : 0;
          });

          for (let i = 0; i < announcements.length; i++) {
            let content = JSON.parse(announcements[i].content);
            let keys = Object.keys(content.entityMap);
            for (let x = 0; x < keys.length; x++) {
              if( !Array.isArray(content.entityMap[keys[x]].data.url)){
                continue;
              }
              let url = content.entityMap[keys[x]].data.url[0].split("?")[0];
              let fileName =  url.substring(url.lastIndexOf("/") + 1);

              let signedUrl = (await axiosInstance.post("/signedurlget", {
                objNames: [ fileName ]
              },{headers: {'auth_id' : idtoken}}))?.data ?? null;

              if (signedUrl) {
                content.entityMap[keys[x]].data.url[0] = signedUrl;
                announcements[i].content = JSON.stringify(content);
              }
          }}

          setAnnouncements(announcements);
        }
      } catch (err) {
        // To be removed once edge lambda implementation for authorization is done.
        if (idtoken === undefined) {
          setPageLoaderProps(true);
        } else {
          setAlertProps({show: true, alertMessage: 'Failed to load announcements. Try again later! '});
        }
      } finally {
        // turn off page loading after api call.
        setPageLoaderProps(false);
      }
    }
    readAnnouncements();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (announcement) => {
    setPageLoaderProps(true);
    const idtoken = getIDTokenFromCookie();
    const newArr = announcements
      .slice(0, announcements.indexOf(announcement))
      .concat(announcements.slice(announcements.indexOf(announcement) + 1));
    axiosInstance
      .post("/announcementsdelete", { msgCreated: announcement.msgCreated },{headers: {"auth_id":idtoken}} )
      .then((response) => {
        if (response.data) setAnnouncements(newArr);
        setAlertProps({ show: true, severity:"success", alertMessage: 'Announcement Deleted successfuly! '});
      })
      .catch((error) =>{
        setAlertProps({ show: true, alertMessage: 'Failed to delete announcement. Try again later! '});
      }).finally(() => {
      // turn off page loading after api call.
      setPageLoaderProps(false);
    });

    setDeleteDialogOpen(false);
  };

  return (
    <Grid container>
      { pageLoaderProps && <PageLoader pageLoaderProps={pageLoaderProps} />}
      { alertProps && <AlertComponent alertProps={alertProps}/> }
      {/* Page header */}
      <Grid
        item
        container
        xs={12}
        className={classes.marginTop}
        justifyContent="space-between"
      >
        <Grid item>
          {/* dummy hidden button to maintain flexbox positioning */}
          <Button
            variant="contained"
            color="primary"
            className={`${classes.headerHiddenButton} ${classes.hiddenButton}`}
          >
            Primary
          </Button>
        </Grid>

        <Grid item>
          <Typography variant="h4">Announcements</Typography>
        </Grid>

        <Grid item>
          { isUserAdmin ? <Link to="/createAnnouncement" style={{textDecoration: "none"}}>
            <Button
                variant="contained"
                color="primary"
                className={classes.headerButton}
            >
              Create
            </Button>
          </Link>:"" }
        </Grid>
      </Grid>

      {/* Page content */}
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        className={classes.gridContent}
      >
        {
          modalAnnouncement &&
          <Modal
              open={modalOpen}
              onClose={handleModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Card variant="outlined" key={modalAnnouncement.title} >
                <CardHeader title={modalAnnouncement.title} className={classes.centerText}
                            action={
                                <IconButton variant="contained" onClick={ handleModalClose }>
                                  <Tooltip title="Close" placement="top-end" arrow>
                                    <CloseIcon/>
                                  </Tooltip>
                                </IconButton>
                            }
                />
                <CardContent>
                  <span className={classes.centerText}>Announcement Date: {modalAnnouncement.msgActivation.split("T")[0]}</span>
                  <MuiThemeProvider theme={showAnnouncementEditorModel}>
                    <MUIRichTextEditor
                        toolbar={false}
                        readOnly={true}
                        defaultValue={modalAnnouncement.content}
                    />
                  </MuiThemeProvider>
                </CardContent>
              </Card>
            </Box>
          </Modal>
        }

        {announcements.map((announcement, index) => (
          <Grid item xs={9} key={index}>
            <Box mb={3}>
              <Card variant="outlined" key={announcement.title} className={classes.cardShadow} >
                <CardHeader
                    title = {
                      <Typography variant="h5" onClick={()=>handleModalOpen(announcement)}>
                        { announcement.title }
                      </Typography>
                    }
                    subheader = {
                        <Typography variant="subtitle2" onClick={()=>handleModalOpen(announcement)}>
                         { "Announcement Date:" + announcement.msgActivation.split("T")[0] }
                        </Typography>
                    }
                    action={
                      isUserAdmin === true ?
                        <Tooltip title="Delete" placement="top-end" arrow>
                          <Fab size="small" color="secondary" aria-label="delete"
                               style={{marginTop: "18px"}}
                               onClick={() => {
                                 setDeleteDialogOpen(true);
                                 setToDelete(announcement);
                               }}
                          >
                            <DeleteIcon/>
                          </Fab>
                        </Tooltip>
                        : ""
                    }
                />
              </Card>
            </Box>
          </Grid>
        ))}

        {/* dialogue for delete confirmation */}
        <DeleteDialog show={deleteDialogOpen} deleteMessage={'Are you sure you want to delete this announcement?'}
          onClose={() => setDeleteDialogOpen(false)}
          onDelete={
            () => {
              handleDelete(toDelete);
              setToDelete({});
            }
          }
        />
      </Grid>
    </Grid>
  );
};

export default Home;
