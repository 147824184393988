import Cookies from "js-cookie";
import axios from "axios";

const userInfoCookieName = "dtEngCentralSessionId";
const sessionCookieName = "dtEngCentralSession";
const idTokenCookieName = 'dtEngCentral'
const accessTokenCookieName = 'dtEngCentralAccess'

const stage_apis = {
  gamma: "https://engineering-central-login-gamma.design-tech.a2z.com",
  prod: "https://engineering-central-login.design-tech.a2z.com"
};

const coreApiEndpoint =  () => {
  if(window.location.href.indexOf("engineering-central-ukarda.")>0 || window.location.href.indexOf("alpha.")>0 || window.location.href.indexOf("beta.")>0 ||window.location.href.indexOf("gamma.")>0)
    return stage_apis.gamma;
  else
    return stage_apis.prod;
}

function getUserEmail() {
  let sessionCookie = Cookies.get("dtEngCentralSession");
  let sessionVars = atob(sessionCookie);
  sessionVars = JSON.parse(sessionVars);
  return sessionVars["user_name"];
}

function validateSession() {
  let sessionCookie = undefined;
  let sessionVars = undefined;
  try {
    sessionCookie = Cookies.get(sessionCookieName);
    sessionVars = atob(sessionCookie);
    sessionVars = JSON.parse(sessionVars);
  } catch (err) {
    // if no session found; reset everything
    Cookies.remove(sessionCookieName, {
      path: "/",
      domain: "design-tech.a2z.com",
    });
    console.log("error in revalidation, getting cookies again");
    createSession();
    return;
  }

  let sessionUrl = coreApiEndpoint() + "/session/validate/";
  const url = sessionUrl + sessionVars["sid"];
  const instance = axios.create({
    withCredentials: true,
    headers: {
      "X-XSRF-TOKEN": sessionVars["xf"],
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  instance
      .post(url)
      .then((response) => {
        console.log("revalidation success");
        if (!isLabUser()) {
          let getUrl = window.location;
          let baseUrl = getUrl.protocol + "//" + getUrl.host+"/no-access";
          window.location.href = encodeURI(baseUrl);
        }else{
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("revalidation error");
        Cookies.remove(userInfoCookieName, {
          path: "/",
          domain: "design-tech.a2z.com",
        });
      });
}

function getAuthTokenFromCookie() {
  let token = undefined
  try {
    let accessTokenCookie = Cookies.get(accessTokenCookieName)
    let accessTokenVars = atob(accessTokenCookie)
    accessTokenVars = JSON.parse(accessTokenVars)
    token = accessTokenVars['at']
  } catch (err) {
    console.log('There was an error getting auth token revalidating session')
    validateSession()
  }
  return token
}

function getIDTokenFromCookie() {
  let token = undefined;
  try {
    let idTokenCookie = Cookies.get(idTokenCookieName);
    let idTokenVars = atob(idTokenCookie);
    idTokenVars = JSON.parse(idTokenVars);
    token = idTokenVars["it"];
  } catch (err) {
    // console.log("There was an error getting id token revalidating session");
    validateSession();
  }
  return token;
}

function isLabUser() {
  // for preview users
  let pu = undefined;

  try {
    let sessionCookie = Cookies.get(sessionCookieName);
    let sessionVars = atob(sessionCookie);
    sessionVars = JSON.parse(sessionVars);
    pu = sessionVars["labu"];
  } catch (err) {
    validateSession();
    return false;
  }
  return typeof pu !== "undefined" && pu === "1";
}

function isAdmin() {
  // for admins
  let ua = undefined;

  try {
    let sessionCookie = Cookies.get(sessionCookieName);
    let sessionVars = atob(sessionCookie);
    sessionVars = JSON.parse(sessionVars);
    ua = sessionVars["ua"];
    //console.log(sessionVars)
  } catch (err) {
    // console.log(
    //   "There was an error getting admin information revalidating session"
    // );
    validateSession();
    return false;
  }
  return typeof ua !== "undefined" && ua === "1";
}

function hasAccess() {
  // has access
  let ha = undefined;

  try {
    let sessionCookie = Cookies.get(sessionCookieName);
    let sessionVars = atob(sessionCookie);
    sessionVars = JSON.parse(sessionVars);
    ha = sessionVars["ha"];
  } catch (err) {
    console.log(
        "There was an error getting access information revalidating session"
    );
    validateSession();
    return false;
  }
  return typeof ha !== "undefined" && ha === "1";
}

function createSession() {
  console.log("session cookie not found");
  let from_uri = window.location.href;
  let loginUrl = coreApiEndpoint() + "/login";
  loginUrl = loginUrl + "?from_uri=" + from_uri;
  window.location.href = encodeURI(loginUrl);
}

export {
  getUserEmail,
  validateSession,
  getAuthTokenFromCookie,
  getIDTokenFromCookie,
  isLabUser,
  isAdmin,
  hasAccess,
  createSession,
};
