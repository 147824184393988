import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import {axiosInstance} from "../api";
import { Delete, EditRounded ,ExpandMoreRounded} from "@material-ui/icons";
import { getUserEmail,getIDTokenFromCookie } from "../authorization/js/auth-utils";
import { AlertComponent, PageLoader, DeleteDialog } from './presentational';
import {hasAccess, isAdmin} from "../authorization/js/auth-utils"

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  cardContent: {
    paddingTop: "0px",
    overflow: "scroll",
  },
  textCenter: {
    textAlign: "center",
  },
  cardSize: {
    width: "85%",
    maxWidth: "380px",
    overflow: "scroll",
    maxHeight: "245px",
  },
  cardContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  headerHiddenButton: {
    marginLeft: theme.spacing(2),
  },
  hiddenButton: {
    visibility: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    "& .MuiChip-icon": {
      order: 1, // the label has a default order of 0, so this icon goes after the label
      // marginRight: "10px", // add some space between icon and delete icon
      marginRight: theme.spacing(0.5), // add some space between icon and delete icon
      cursor: "pointer",
      fontSize: "20px",
    },
    "& .MuiChip-deleteIcon": {
      order: 2, // since this is greater than an order of 1, it goes after the icon
    },
    "& .MuiChip-label": {
      paddingRight: "0px",
      width: "95px"
    },
  },
  accordionDiv: {
    margin: "1%",
    width: "100%"
  },
  accordionMain: {
    margin: "2% 0",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)",
  },
  accordionDetails: {
    display: "block",
  },
  accordionTypography: {
    fontWeight: "bold",
  },
}));

const Factlab = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [reports, setReports] = useState([]);
  const [toDelete, setToDelete] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [editReport, setEditReport] = useState({});
  const [alertProps, setAlertProps] = useState(null);
  const [pageLoaderProps, setPageLoaderProps] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const isUserAdmin = isAdmin();
  // fetch all announcements at loading
  useEffect(() => {
    // get user's alias
    const idtoken = getIDTokenFromCookie();
    const alias = getUserEmail().split("@")[0];
    let config = {
      headers: {'auth_id' : idtoken},
      params: {
        alias: alias,
      },
    }
    axiosInstance
      .get("/factlabread", config)
      .then((response) => {
        if (response.data != null) setReports(response.data);
      })
      .catch((error) => {
        console.log(error);
        // To be removed once edge lambda implementation for authorization is done.
        if (idtoken === undefined) {
          setPageLoaderProps(true);
        } else {
          setAlertProps({show: true, alertMessage: 'Failed to load Factlab reports. Try again later! '});
        }
      }).finally(() => {
      // turn off page loading after api call.
      setPageLoaderProps(false);
    });
  }, []);

  // opening and closing dialogue
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // opening and closing edit dialogue
  const handleEditOpen = () => {
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };

  // handling redirect to external link
  const handleChipClick = (url) => {
    if (url.indexOf("https://") < 0 && url.indexOf("http://") < 0) {
      url = "http://" + url;
    }
    window.open(url, "_blank");
  };

  // handle edit button click
  const handleEditClick = (e) => {
    // prevent chip from being clicked when
    // edit button is being clicked
    e.stopPropagation();
    handleEditOpen();
  };

  // handle edit button submit
  const handleEditSubmit = () => {
    setPageLoaderProps(true);
    const idtoken = getIDTokenFromCookie();
    axiosInstance
      .put("/factlabput", {
        index: editReport.index,
        ldap: editReport.ldapLabel,
        obj: { reportName: editReport.reportName, url: editReport.reportUrl },
      }, {headers: {'auth_id' : idtoken}})
      .then((response) => {
        if (response.data === true) {
          let ret = [...reports];
          ret[editReport.reportIndex].urls[editReport.index] = {
            reportName: editReport.reportName,
            url: editReport.reportUrl,
          };
          setReports(ret);
        }
      })
        .catch((error) => {
          console.log(error);
          setAlertProps({ show: true, alertMessage: 'Failed to update Factlab report. Try again later! '});
        }).finally(() => {
      // turn off page loading after api call.
      setPageLoaderProps(false);
    });;

    handleEditClose();
    setEditReport({});
  };

  // deleting specific report from group
  const handleDelete = (item) => {
    // remove the deleted report
    setPageLoaderProps(true);
    let arr = reports[item.reportIndex].urls;
    let newArr = arr.slice(0, item.index).concat(arr.slice(item.index + 1));
    const idtoken = getIDTokenFromCookie();
    axiosInstance
      .post("/factlabdelete", {
        ldap: reports[item.reportIndex].ldapLabel,
        urls: newArr,
      },{ headers: {'auth_id' : idtoken},})
      .then((response) => {
        if (response.data === true) {
          let ret = [...reports];
          ret[item.reportIndex].urls = newArr;
          setReports(ret);
        }
      }).catch((error) => {
      console.log(error)
      setAlertProps({ show: true, alertMessage: 'Failed to  delete Factlab report. Try again later! '});
    }).finally(() => {
      // turn off page loading after api call.
      setPageLoaderProps(false);
    });
    setDeleteDialogOpen(false);
    handleClose();
  };

  return (
    <Grid container className={classes.gridContainer}>
      { pageLoaderProps && <PageLoader pageLoaderProps={pageLoaderProps} />}
      { alertProps && <AlertComponent alertProps={alertProps}/> }
      <Grid
        item
        container
        xs={12}
        className={classes.marginTop}
        justifyContent="space-between"
      >
        <Grid item>
          {/* dummy hidden button to maintain flexbox positioning */}
          <Button
            variant="contained"
            color="primary"
            className={`${classes.headerHiddenButton} ${classes.hiddenButton}`}
          >
            Primary
          </Button>
        </Grid>

        <Grid item>
          <Typography variant="h4">Factlab Reports</Typography>
        </Grid>

        <Grid item>
          { isUserAdmin ? <Link to="/createFactlab" style={{textDecoration: "none"}}>
            <Button
                variant="contained"
                color="primary"
                className={classes.headerButton}
            >
              Create
            </Button>
          </Link> : ""}
        </Grid>
      </Grid>

      {/* rendered accordion */}
      <div className={classes.accordionDiv}>
      {reports.map((item, idx) => {
        if (item.urls != null && item.urls.length > 0)
          return (
              <Accordion className={classes.accordionMain}>
                <AccordionSummary
                    expandIcon={<ExpandMoreRounded/>}
                    aria-controls={`panel${idx}a-content`}
                    id={`panel${idx}a--header`}
                >
                  <Typography
                      display="block" className={classes.accordionTypography}
                  >
                    {item.ldapLabel + " (Ldap Group - "+ item.ldapGroup + ")"}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                    className={classes.accordionDetails}
                >
                  {item.urls.map((entry, index) => (
                      <Tooltip title={entry.reportName} placement="top-end" arrow>
                        <Chip
                            key={entry.reportName + entry.url}
                            clickable={true}
                            className={classes.chip}
                            color={"primary"}
                            onClick={() => {
                              handleChipClick(entry.url);
                            }}
                            label={entry.reportName}
                            variant={"outlined"}
                            deleteIcon={ <Delete  color="secondary"/>}
                            icon={
                              <EditRounded
                                  className={classes.editIcon}
                                  onClick={(e) => {
                                    setEditReport({
                                      ldapGroup: item.ldapGroup,
                                      ldapLabel: item.ldapLabel,
                                      reportName: entry.reportName,
                                      reportUrl: entry.url,
                                      index: index,
                                      reportIndex: idx,
                                    });
                                    handleEditClick(e);
                                  }}
                              />
                            }
                            onDelete={() => {
                              setDeleteDialogOpen(true);
                              setToDelete({
                                reportIndex: idx,
                                index: index,
                              });
                            }}
                        />
                      </Tooltip>
                  ))}
                </AccordionDetails>
              </Accordion>
          );
        else return "";
      })}
      </div>

      {/* dialogue for delete confirmation */}
      <DeleteDialog show={deleteDialogOpen} deleteMessage={'Are you sure you want to delete this report?'}
                    onClose={() => setDeleteDialogOpen(false)}
                    onDelete={
                      () => {
                        handleDelete(toDelete);
                        setToDelete({});
                      }
                    }
      />


      {/* dialogue for edit confirmation */}
      <Dialog
        open={editOpen}
        onClose={handleEditClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="reportName"
            label="Report Name"
            onChange={(e) => {
              const val = e.target.value;
              setEditReport((prev) => ({
                ...prev,
                index: prev.index,
                ldapLabel: prev.ldapLabel,
                ldapGroup: prev.ldapGroup,
                reportName: val,
                reportUrl: prev.reportUrl,
              }));
            }}
            defaultValue={editReport.reportName}
            fullWidth
          />
          <TextField
            margin="dense"
            id="reportUrl"
            label="Report URL"
            onChange={(e) => {
              const val = e.target.value;
              setEditReport((prev) => ({
                ...prev,
                index: prev.index,
                ldapLabel: prev.ldapLabel,
                ldapGroup: prev.ldapGroup,
                reportName: prev.reportName,
                reportUrl: val,
              }));
            }}
            defaultValue={editReport.reportUrl}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleEditSubmit} variant="contained" color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Factlab;
