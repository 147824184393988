import React, { useRef, useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {axiosInstance} from "../api";
import {getUserEmail, getIDTokenFromCookie} from "../authorization/js/auth-utils";
import { AlertComponent, PageLoader } from './presentational';
import { useHistory } from "react-router-dom";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  root: {
    padding: theme.spacing(1.5),
  },
}))(TableCell);

// each row should have alternating color
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    width: "800px",
  },
  gridContainer: {
    padding: "5%",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)",
  },
  reportNameCell: {
    width: "140px",
  },
  reportUrlCell: {
    width: "200px",
  },
  actionCell: {
    width: "60px",
    textAlign: "center",
  },
  borderCell: {
    borderRight: "1px solid black",
  },
  centerText: {
    textAlign: "center",
  },
  buttonMargin: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));
const urlmap = new Map();
const ldapmap = new Map();
export default function CreateFactlab() {
  const history = useHistory();
  const classes = useStyles();
  // for urls and report names
  const [url, setUrl] = useState("");
  const [reportName, setReportName] = useState("");
  const [urlList, setUrlList] = useState([]);
  const [banner, setBanner] = useState("");
  const [bannerError, setBannerError] = useState(false);
  let [alertProps, setAlertProps] = React.useState(null);
  let [pageLoaderProps, setPageLoaderProps] = React.useState(false);
  // for ldap group and group names
  const [ldapLabel, setLdapLabel] = useState("");
  const [ldapGroup, setLdapGroup] = useState("");
  const [ldapList, setLdapList] = useState([]);

  // for form validation
  const [ldapListError, setLdapListError] = useState(false);
  const [urlListError, setUrlListError] = useState(false);

  // used to focus report name after submit
  let reportNameInput = useRef(null);
  // used to focus group name after submit
  let ldapLabelInput = useRef(null);

  // handle submission of entire form (ldap and urls)
  const handleFormSubmit = () => {
    setAlertProps(null);
    if (urlList.length === 0) {
      setUrlListError(true);
      return;
    }
    if (ldapList.length === 0) {
      setLdapListError(true);
      return;
    }
    setPageLoaderProps(true);
    let obj = {
      ldaps: ldapList,
      urls: urlList,
    };
    const idtoken = getIDTokenFromCookie();
    axiosInstance.post("/factlabwrite", obj,{headers: {"auth_id":idtoken}}).then((response) => {
      if (response.data != null && response.data['status']!=null )
        setAlertProps({ show: true, alertMessage: 'Ldap Label already exists! '});
      else
        setAlertProps({severity:"success", show: true, alertMessage: 'Successfully Added Factlab Report ! '});
        setUrlList([]);
        setLdapList([]);
        urlmap.clear();
        ldapmap.clear();
        //history.push('/factlab');
    }).catch((error) => {
      setAlertProps({ show: true, alertMessage: 'Failed to add Factlab Report. Try again later! '});
    }).finally(() => {
      setPageLoaderProps(false);
    });
  };

  // handles submission of url + report name
  const handleUrlSubmit = () => {
    setAlertProps(null);
    if (url === "" || reportName === "") {
      return;
    }
    if (urlmap.has(url)){
      reportNameInput.current.focus();
      setAlertProps({show: true, alertMessage: 'You have already added same report url! '});
      return;
    }else {
      urlmap.set(url, reportName);
    }
    setUrlList((prevState) => [
      ...prevState,
      { url: url, reportName: reportName },
    ]);

    setUrl("");
    setReportName("");
    reportNameInput.current.focus();
  };

  // allow users to delete rows
  const handleUrlRemove = (id,url ) => {
    urlmap.delete(url)
    const newList = urlList.filter((item) => item.url + item.reportName !== id);
    setUrlList(newList);
  };

  // handles submission of ldapGroup + group name
  const handleLdapSubmit = () => {
    setAlertProps(null);
    if (ldapLabel === "" || ldapGroup === "") return;

    if (ldapmap.has(ldapGroup)){
      ldapLabelInput.current.focus();
      setAlertProps({show: true, alertMessage: 'You have already added same Ldap Group! '});
      return;
    }else {
      ldapmap.set(ldapGroup, ldapLabel);
    }

    setLdapList((prevState) => [
      ...prevState,
      { ldapLabel: ldapLabel, ldapGroup: ldapGroup },
    ]);
    setLdapLabel("");
    setLdapGroup("");
    ldapLabelInput.current.focus();
  };

  // allow users to delete rows
  const handleLdapRemove = (id, ldapGroup) => {
    ldapmap.delete(ldapGroup);
    const newList = ldapList.filter(
      (item) => item.ldapLabel + item.ldapGroup !== id
    );
    setLdapList(newList);
  };

  return (
      <> { pageLoaderProps && <PageLoader pageLoaderProps={pageLoaderProps} />}
        { alertProps && <AlertComponent alertProps={alertProps}/> }
    <Grid container className={classes.gridContainer} justifyContent="center">
      {/* if there is a banner, show banner with appropriate color */}
      <Grid item xs={12}>
        <Box mb={4}>
          <Typography variant="h4" className={classes.centerText}>
            Create Quicksight Report Mappings
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" className={classes.centerText}>
          Enter report name and urls:
        </Typography>
      </Grid>
      <Grid item>
        <TableContainer className={classes.table} component={Paper}>
          <Table aria-label="customized table">
            {/* table header elements */}
            <TableHead>
              <TableRow>
                <StyledTableCell
                  className={classes.reportNameCell}
                  style={{ borderRight: "1px solid white" }}
                >
                  Report Name
                </StyledTableCell>
                <StyledTableCell
                  className={classes.reportUrlCell}
                  style={{ borderRight: "1px solid white" }}
                >
                  Report URL
                </StyledTableCell>
                <StyledTableCell className={classes.actionCell}>
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>

            {/* url list body */}
            <TableBody>
              {urlList.map((row) => (
                <StyledTableRow key={row.url + row.reportName}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={`${classes.borderCell} ${classes.reportNameCell}`}
                  >
                    {row.reportName}
                  </StyledTableCell>

                  <StyledTableCell
                    className={`${classes.borderCell} ${classes.reportUrlCell}`}
                  >
                    <TextField fullWidth value={row.url} />
                  </StyledTableCell>

                  <StyledTableCell className={classes.actionCell}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleUrlRemove(row.url + row.reportName, row.url)}
                    >
                      Delete
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              {/* this is the input row (last row) */}
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  className={`${classes.borderCell} ${classes.reportNameCell}`}
                >
                  <TextField
                    error={urlListError ? true : false}
                    helperText={
                      urlListError
                        ? "Please add at least one report/url entry!"
                        : ""
                    }
                    id="reportName"
                    label="Report Name"
                    inputRef={reportNameInput}
                    value={reportName}
                    onChange={(e) => {
                      setUrlListError(false);
                      setReportName(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleUrlSubmit();
                    }}
                  />
                </StyledTableCell>

                <StyledTableCell
                  className={classes.borderCell}
                  style={{ maxWidth: "200px" }}
                >
                  <TextField
                    id="urlInput"
                    label="Report URL"
                    fullWidth
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleUrlSubmit();
                    }}
                  />
                </StyledTableCell>

                <StyledTableCell className={classes.actionCell}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setLdapListError(false);
                      handleUrlSubmit();
                    }}
                  >
                    Add
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* this section is for ldap groups and group names */}
      <Grid item xs={12}>
        <Box mt={6}>
          <Typography variant="h5" className={classes.centerText}>
            Which Ldap Groups Should the Urls Belong To?
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <TableContainer className={classes.table} component={Paper}>
          <Table aria-label="customized table">
            {/* table header elements */}
            <TableHead>
              <TableRow>
                <StyledTableCell
                  className={classes.reportNameCell}
                  style={{ borderRight: "1px solid white" }}
                >
                  Label
                </StyledTableCell>
                <StyledTableCell
                  className={classes.reportUrlCell}
                  style={{ borderRight: "1px solid white" }}
                >
                  Ldap Group
                </StyledTableCell>
                <StyledTableCell className={classes.actionCell}>
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>

            {/* group name and ldap group body */}
            <TableBody>
              {ldapList.map((row) => (
                <StyledTableRow key={row.ldapLabel + row.ldapGroup}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={`${classes.borderCell} ${classes.reportNameCell}`}
                  >
                    {row.ldapLabel}
                  </StyledTableCell>

                  <StyledTableCell
                    className={`${classes.borderCell} ${classes.reportUrlCell}`}
                    component="th"
                    scope="row"
                  >
                    {row.ldapGroup}
                  </StyledTableCell>

                  <StyledTableCell className={classes.actionCell}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() =>
                        handleLdapRemove(row.ldapLabel + row.ldapGroup, row.ldapGroup)
                      }
                    >
                      Delete
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              {/* this is the input row (last row) */}
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  className={`${classes.borderCell} ${classes.reportNameCell}`}
                >
                  <TextField
                    error={ldapListError ? true : false}
                    helperText={
                      ldapListError ? "Please add at least one ldap group!" : ""
                    }
                    id="ldapLabel"
                    label="Ldap Label"
                    inputRef={ldapLabelInput}
                    value={ldapLabel}
                    onChange={(e) => {
                      setLdapListError(false);
                      setLdapLabel(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleLdapSubmit();
                    }}
                  />
                </StyledTableCell>

                <StyledTableCell
                  className={classes.borderCell}
                  style={{ maxWidth: "200px" }}
                >
                  <TextField
                    id="ldapGroup"
                    label="Ldap Group"
                    fullWidth
                    value={ldapGroup}
                    onChange={(e) => setLdapGroup(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleLdapSubmit();
                    }}
                  />
                </StyledTableCell>

                <StyledTableCell className={classes.actionCell}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleLdapSubmit}
                  >
                    Add
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* submit button */}
      <Grid  className={classes.buttonMargin}  justifyContent = "center" item  container spacing={2}>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFormSubmit}
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
              variant="contained"
              color="primary"
              onClick={()=>history.push("/factlab")}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
      </>
  );
}
