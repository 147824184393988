import React, {useEffect, useState} from "react";
import { Form, Field } from "react-final-form";
import {
  Typography,
  Paper,
  Grid,
  Button,
  CssBaseline,
    Card,
    makeStyles
} from "@material-ui/core";
import axios from "axios";
import {getUserEmail, getIDTokenFromCookie} from "../authorization/js/auth-utils";
import {wocAxiosInstance} from "../api";
import { AlertComponent, PageLoader } from './presentational';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    cardstyle: {
        margin: "5%",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)",
    },
}));
export default function CreateWindchillInstanceForm() {
  const [message, setMessage] = useState("");
  const [data, setData] = useState({});
  const history = useHistory();
  const classes = useStyles();
  let [alertProps, setAlertProps] = useState(null);
  let [pageLoaderProps, setPageLoaderProps] = useState(false);
  useEffect(() =>  {
    const email = getUserEmail();
    const username = getUserEmail().split("@")[0];
    setData({"owner" : username, "dnsName": username, "email": email})
  }, [])

  const onSubmit = async (values, form) => {
      setPageLoaderProps(true)
      const idtoken = getIDTokenFromCookie();
      const payload = JSON.stringify(values, 0, 2);
      console.log(payload);
      let request_id =""
      wocAxiosInstance.post(
            "/windchill/create",
            payload, {headers: {'auth_id' : idtoken}}
        ).then(function (response) {
          request_id = response.data.split(":")[1].trim();
          setMessage(`REQUEST_ID: ${request_id} \nCreation Status: IN PROGRESS`);
          setAlertProps({ severity:'success', show: true, alertMessage: 'Request to create windchill instance is submitted successfully!'})
          //history.push('/windchill');
        }).catch(function (error) {
          console.log(error);
          setAlertProps({ show: true, alertMessage: 'Failed to submit request to create windchill instance. Try again later or contact DT Admin team! '});
      }).finally(() => {
          // turn off page loading after api call.
          setPageLoaderProps(false);
      });
        //console.log(createResponse);

        /*const statusPayload = { requestID: request_id };
        console.log(statusPayload);
        const creationStatusResponse = await wocAxiosInstance.post(
            "/windchill/status",
            statusPayload, {headers: {'auth_id' : idtoken}}
        );
        console.log(creationStatusResponse);
        const res = JSON.stringify(creationStatusResponse.data);
        console.log(res);*/

      // clear form
      Object.keys(values).forEach(key => {
          form.change(key, undefined);
          form.resetFieldState(key);
      });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.owner) {
      errors.owner = "Required";
    }
    if (!values.dnsName) {
      errors.dnsName = "Required";
    }
    if (!values.email) {
      errors.email = "Required";
    }
    return errors;
  };

  const formReset = () => {
    setData({});
  }

  const formFields = [
    {
      size: 12,
      field: (
        <div>
            <Grid container spacing={2}>
                <Grid item  xs={5}>
                   <label style={{color:"blue"}}>Owner </label>
                </Grid>
                <Grid item  xs={7}>
                      <Field
                          name="owner"
                          margin="none"
                          required={true}
                          component="input"
                          placeholder="Owner"
                      />
                </Grid>
            </Grid>
        </div>
      ),
    },
    {
      size: 12,
      field: (
          <div>
          <Grid container spacing={2}>
              <Grid item xs={5}>
                  <label style={{color:"blue"}}>DNS Name </label>
              </Grid>
              <Grid item xs={7} >
                  <Field
                    name="dnsName"
                    margin="none"
                    required={true}
                    component="input"
                    placeholder="DNS Name"
                />
              </Grid>
          </Grid>
        </div>
      ),
    },
    {
      size: 12,
      field: (
          <div>
              <Grid container spacing={2}>
                  <Grid item xs={5} >
                      <label style={{color:"blue"}}>Email </label>
                  </Grid>
                  <Grid item xs={7}>
                    <Field
                      type="email"
                      name="email"
                      margin="none"
                      required={true}
                      component="input"
                      placeholder="Email"
                    />
                  </Grid>
              </Grid>
          </div>
      ),
    },
  ];

  return (
      <>
          { pageLoaderProps && <PageLoader pageLoaderProps={pageLoaderProps} />}
          { alertProps && <AlertComponent alertProps={alertProps}/> }
      <Card variant="outlined" className={classes.cardstyle}>
      <div style={{ padding: 16, margin: "auto", maxWidth: 600 }}>
        <CssBaseline />
            <Typography variant="h4" align="center" component="h1" gutterBottom>
              <img
                  src="/images/windchillIcon.png"
                  alt=""
                  style={{ maxHeight: "24px", maxWidth: "24px" }}
              />{" "}
              Create Windchill Instance
            </Typography>
        <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={data}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Paper style={{ padding: 16 }}>
                    <Grid container alignItems="flex-start" spacing={2}>
                      {formFields.map((item, idx) => (
                          <Grid item xs={item.size} key={idx}>
                            {item.field}
                          </Grid>
                      ))}
                        <Grid item style={{ marginTop: 16, textAlign: "center" }}  container spacing={2}>
                          <Grid item xs={4}>
                            <Button
                                type="button"
                                variant="contained"
                                onClick={formReset}
                                disabled={submitting || pristine}
                            >
                              Reset
                            </Button>
                          </Grid>
                          <Grid item xs={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={submitting}
                            >
                              Submit
                            </Button>
                          </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=> history.push("/windchill")}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                  </Paper>
                  <pre>{message}</pre>
                </form>
            )}
        />
      </div>
      </Card>
      </>
  );
}