import React, { useRef, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export function AlertComponent({ alertProps }) {
    const [state, setState] = useState({
        open: alertProps?.show ?? false, // boolean - true, false
        vertical: alertProps?.vertical ?? 'top',  // bottom
        horizontal: alertProps?.vertical ?? 'center', // center, right, left
        autoHideDuration: alertProps?.autoHideDuration ?? 5000, // milliseconds (number)
        alertMessage: alertProps?.alertMessage ?? 'Failed to load. Try again later!',
        severity: alertProps?.severity ?? 'error', // error, warning, info, success
    });

    const { vertical, horizontal, open, autoHideDuration, alertMessage, severity } = state;

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                key={"customAppAlertNotification"}
                autoHideDuration={autoHideDuration}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    { alertMessage }
                </Alert>
            </Snackbar>
        </div>
    );
}