import React from "react";
import Navbar from "./Navbar";

const WrapperComponent = ({ component }) => {
  return (
    <div>
      {/* the child component prop goes into navbar to give it the animation from side panel */}
      <Navbar child={component} />
    </div>
  );
};

export default WrapperComponent;
