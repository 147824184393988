import axios from "axios";

// ALL API ENDPOINTS

const fl_stage_apis = {
  alpha: "https://api-fl.alpha.engineering-central.design-tech.a2z.com",
  beta: "https://api-fl.beta.engineering-central.design-tech.a2z.com",
  gamma: "https://api-fl.gamma.engineering-central.design-tech.a2z.com",
  prod: "https://api-fl.engineering-central.design-tech.a2z.com"
};

const factlabCoreApiEndpoint =  () => {
  if(window.location.href.indexOf("alpha.")>0 || window.location.href.indexOf("engineering-central-ukarda.")>0)
    return fl_stage_apis.alpha;
  else if(window.location.href.indexOf("beta.")>0 )
    return fl_stage_apis.beta;
  else  if(window.location.href.indexOf("gamma.")>0 )
    return fl_stage_apis.gamma;
  else
    return fl_stage_apis.prod;
}

const factlabAxiosInstance = axios.create({
  baseURL:factlabCoreApiEndpoint()
});

const woc_stage_apis = {
  alpha: "https://api-woc.alpha.engineering-central.design-tech.a2z.com",
  beta: "https://api-woc.beta.engineering-central.design-tech.a2z.com",
  gamma: "https://api-woc.gamma.engineering-central.design-tech.a2z.com",
  prod: "https://api-woc.engineering-central.design-tech.a2z.com"
};

const wocCoreApiEndpoint =  () => {
  if(window.location.href.indexOf("alpha.")>0 || window.location.href.indexOf("engineering-central-ukarda.")>0)
    return woc_stage_apis.alpha;
  else if(window.location.href.indexOf("beta.")>0 )
    return woc_stage_apis.beta;
  else  if(window.location.href.indexOf("gamma.")>0 )
    return woc_stage_apis.gamma;
  else
    return woc_stage_apis.prod;
}
const wocAxiosInstance = axios.create({
  baseURL:wocCoreApiEndpoint()
});

export {factlabAxiosInstance as axiosInstance, wocAxiosInstance};
