import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import {isLabUser, createSession, validateSession, getAuthTokenFromCookie, hasAccess} from './auth-utils';

export default function authorize(){
	let sessionCookie = Cookies.get('dtEngCentralSession')

	if(sessionCookie === undefined){
		createSession();
	}
	else {
		if (document.getElementById("username")) {
			document.getElementById("username").textContent = getUserName()
		}
		let supportCookie = Cookies.get('dtEngCentralSessionId')
		if (supportCookie === undefined) {
			validateSession()
		} else {
			try {
				let at = getAuthTokenFromCookie()
				let exp = undefined
				if (at !== undefined) {
					at = jwt_decode(at)
					exp = at['exp'] // expiry
				}
				let currentTime = new Date().getTime() / 1000;

				if (exp === undefined || currentTime > exp) {
					Cookies.remove('dtEngCentral', {
						path: '/',
						domain: 'design-tech.a2z.com'
					})
					validateSession()
				} else {
					if (!isLabUser()) {
						let getUrl = window.location;
						let baseUrl = getUrl.protocol + "//" + getUrl.host+"/no-access";
						window.location.href = encodeURI(baseUrl);
					}else{
						//window.location.reload();
					}
				}
			} catch (err) {
				Cookies.remove('dtEngCentral', {
					path: '/',
					domain: 'design-tech.a2z.com'
				})
				//window.location.reload()
			}
		}
	}
}

function getUserName(){
	let idToken = Cookies.get('dtEngCentral')
	if(idToken)
	{let idvars = atob(idToken)
		let idObj = jwt_decode(idvars)

		return idObj["name"]+" "+ idObj["family_name"];}
	return "";
}
export function getalias(){
	let sessionCookie = Cookies.get('dtEngCentralSession')
	let sessionVars = atob(sessionCookie)
	sessionVars = JSON.parse(sessionVars)

	return sessionVars["user_name"].split("@")[0];
}
//export default authorize;