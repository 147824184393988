import { createTheme } from "@material-ui/core/styles";

// theme provider for MUIRichTextEditor
const createAnnouncementEditorTheme = createTheme({
  palette: {},
  overrides: {
    MUIRichTextEditor: {
      root: {
        border: "1px solid gray",
        width: "100%",
      },
      editorContainer: {
        padding: "20px",
        height: "75%",
      },
      editor: {
        wordBreak: "break-word",
        overflowY: "auto",
        height: "400px",
      }
    },
  },
});

// theme provider for MUIRichTextEditor
const showAnnouncementEditorTheme = createTheme({
  palette: {},
  overrides: {
    MUIRichTextEditor: {
      root: {
        // border: "1px solid gray",
        width: "100%",
      },
      editorContainer: {
        height: "75%",
      },
      editor: {
        wordBreak: "break-word",
        overflowY: "auto",
        maxHeight: "120px",
      }
    },
  },
});

// theme provider for MUIRichTextEditor
const showAnnouncementEditorModel = createTheme({
  palette: {},
  overrides: {
    MUIRichTextEditor: {
      root: {
        // border: "1px solid gray",
        width: "100%",
      },
      editorContainer: {
        height: "75%",
      },
      editor: {
        wordBreak: "break-word",
        overflowY: "auto",
        height: "600px",
      }
    },
  },
});

export {
  createAnnouncementEditorTheme,
  showAnnouncementEditorTheme,
  showAnnouncementEditorModel
};
