import React, { useRef, useState }from 'react';
import {Backdrop, CircularProgress, Box, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 1
    },
}));

export function PageLoader({pageLoaderProps}) {
    const classes = useStyles();
    const [state, setState] = useState({
        open: pageLoaderProps ?? false, // boolean - true, false
    });
    const { open } = state;
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff' }}
                className={classes.backdrop}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
