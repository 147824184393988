import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import WrapperComponent from "./components/WrapperComponent";
import NoAccess from "./components/No-Access";
import WindchillDashboard from "./components/WindchillDashboard";
import CreateAnnouncement from "./components/CreateAnnouncement";
import CreateFactlab from "./components/CreateFactlab";
import Factlab from "./components/Factlab";
import CreateWindchillInstanceForm from "./components/CreateWindchillInstanceForm";
import DeleteWindchillInstanceForm from "./components/DeleteWindchillInstanceForm";
import TransferWindchillInstanceForm from "./components/TransferWindchillInstanceForm";
import RIDashboard from "./components/RIDashboard";
import Dashboard from "./components/Dashboard";
import CreateDashboardReport from "./components/CreateDashboardReport";
import authorize from "./authorization/js/authorize-user";

function App() {
  useEffect(() => {
    authorize();
  }, []);

  return (
    <Router>
      <div className="app">
        <Switch>
          <Route exact path="/factlab">
            <WrapperComponent component={<Factlab />} />
          </Route>
          <Route exact path="/dashboard">
            <WrapperComponent component={<Dashboard />} />
          </Route>
          <Route exact path="/ri">
            <WrapperComponent component={<RIDashboard />} />
          </Route>
          <Route exact path="/windchill">
            <WrapperComponent component={<WindchillDashboard />} />
          </Route>
          <Route exact path="/windchill/create">
            <WrapperComponent component={<CreateWindchillInstanceForm />} />
          </Route>
          <Route exact path="/windchill/delete">
            <WrapperComponent component={<DeleteWindchillInstanceForm />} />
          </Route>
          <Route exact path="/windchill/transfer">
            <WrapperComponent component={<TransferWindchillInstanceForm />} />
          </Route>
          <Route exact path="/createAnnouncement">
            <WrapperComponent component={<CreateAnnouncement />} />
          </Route>
          <Route exact path="/createFactlab">
            <WrapperComponent component={<CreateFactlab />} />
          </Route>
          <Route exact path="/createDashboardReport">
            <WrapperComponent component={<CreateDashboardReport />} />
          </Route>
          <Route exact path="/">
            <WrapperComponent component={<Home />} />
          </Route>
          <Route exact path="/no-access">
            <NoAccess/>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
