import React, { useEffect, useRef, useState } from "react";
import {Container, makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    centerText: {
        textAlign: "center",
    },
    marginTop: {
        marginTop: theme.spacing(4),
        textAlign: "center",
    },
    marginSide: {
        marginLeft: theme.spacing(4),
    },
    formInput: {
        width: "200px",
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    dateInput: {
        width: "160px",
        marginRight: theme.spacing(2),
    },
    bodyInput: {
        marginTop: theme.spacing(2),
    },
    buttonTColor: {
        color:"#757de8"
    },
    buttonTextColor: {
        color:"White"
    },
    card: {
        //margin: "10%",
        padding: "10%",
        width:"50%",
        marginLeft: "25%",
        marginTop:"10%",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)",
    },
}));
const NoAccess = () => {
    const classes = useStyles();
return (
    <Grid container className={classes.card} justifyContent="center">
        <Grid item xs={12} className={classes.centerText}>
            <h1 style={{color:"#757de8"}}>Design Tech Engineering Central!</h1>
            <h1>Access Denied!</h1>
        </Grid>
        <Grid item xs={12} className={classes.centerText}>
            <body className={classes.centerText}>
            We understand that you want access  <span style={{fontWeight:"Bold"}}>Design Tech Engineering Central</span>. Currently you do not have permission. Please email dtec-dev@amazon.com for the access or click the button below to request access.
            </body>
        </Grid>
        <Grid item xs={12} className={classes.marginTop}>
            <Button color="primary" variant="contained" className={classes.buttonTColor}>
                <a href="https://sim.amazon.com/issues/create?template=6247a608-c509-40c7-b5c1-b987000b1b1e" target='_blank' rel="noreferrer" className ={classes.buttonTextColor}>Request Access</a>
            </Button>
        </Grid>
    </Grid>
)};

export default NoAccess;

