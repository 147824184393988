import React from "react";
import MUIDataTable from "mui-datatables";
import {Button} from "@material-ui/core";
import Box from "@material-ui/core/Box";

export default function RIDashboard() {
    const columns = ["Instance Type", "RI Count", "EC2 Count",
        {
            name: "Suggested Action",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const ri_count = tableMeta['rowData'][1];
                    const ec2_count = tableMeta['rowData'][2];
                    let type = "";
                    if(ri_count === ec2_count){
                        type = "NO ACTION";
                        return(
                            <Button variant="contained" disabled>
                                {type}
                            </Button>
                        )
                    }
                    else if(ri_count > ec2_count){
                        type = "RELEASE";
                        return(
                            <Button color="secondary" variant="contained" onClick={() => { handleAction(type) }}>
                                {type}
                            </Button>
                        )
                    }
                    else if(ri_count < ec2_count){
                        type = "BUY";
                        return(
                            <Button color="primary" variant="contained" onClick={() => { handleAction(type) }}>
                                {type}
                            </Button>
                        )
                    }
                }
            }
        }];

    async function handleAction(type) {
        console.log(type);
        if(type === "BUY"){

        }
        else if(type === "SELL"){


        }
        else if(type === "NO ACTION"){

        }
    }

    const data = [
        ["m5.24xlarge-Linux/UNIX","2","2"],
        ["m5d.8xlarge-Windows","7","8"],
        ["m5d.large-Red Hat Enterprise Linux","15","16"],
        ["m5.2xlarge-Windows","20","15"],
        ["m5d.2xlarge-Linux/UNIX","4","3"],
    ];

    const options = {
        filterType: "dropdown",
        selectableRows: "none",
        tableBodyMaxHeight: 'auto',
    };

    return(
        <Box>
            <MUIDataTable
                title={"EC2 Instances"}
                data={data}
                columns={columns}
                options={options}
            />
        </Box>
    );
}