import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ContentCopyIcon from '@material-ui/icons/FileCopyOutlined';
import {makeStyles, Grid} from "@material-ui/core";
import { Link } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { AlertComponent } from './presentational';
const useStyles = makeStyles((theme) => ({
    card: {
        margin: 10,
        variant: "outlined",
    },
    attributes: {
        marginTop: 10,
        fontWeight: "fontWeightBold",
    },
    actions: {
        display: 'flex',
    },
    expand: {
        marginLeft: 'auto',
        size: 'small'
    }
}));

export default function WindchillCard(props) {
    let [alertProps, setAlertProps] = React.useState(null);
    let [copyText, setCopyText] = React.useState(false);
    const classes = useStyles();
    const {instances, dataIndex, type} = props;
    const EC2_URL = 'https://us-west-2.console.aws.amazon.com/ec2/v2/home?region=us-west-2#InstanceDetails:instanceId=';

    let instanceName = instances[dataIndex].instanceName;
    let instanceID = instances[dataIndex].instanceID;
    let instanceType = instances[dataIndex].instanceType;
    let privateIP = instances[dataIndex].privateIP;
    let instanceURL = `${EC2_URL}${instanceID}`;

    if(instanceName.includes("-APP") && type === "DB"){
        instanceName = instanceName.split("-")[0] + "-DB";
        const dataIndexDB = dataIndex+1;
        instanceID = instances[dataIndexDB].instanceID;
        instanceType = instances[dataIndexDB].instanceType;
        privateIP = instances[dataIndexDB].privateIP;
        instanceURL = `${EC2_URL}${instanceID}`;
    }
    else if(instanceName.includes("-DB") && type === "APP"){
        instanceName = instanceName.split("-")[0] + "-APP";
        const dataIndexAPP = dataIndex-1;
        instanceID = instances[dataIndexAPP].instanceID;
        instanceType = instances[dataIndexAPP].instanceType;
        privateIP = instances[dataIndexAPP].privateIP;
        instanceURL = `${EC2_URL}${instanceID}`;
    }
    let linkElement =
        <Link style={{ textDecoration: 'none' }}
            to={{
                pathname: instanceURL
            }}
            target="_blank"
        >
            {instanceName}
        </Link>

    let handleCopy = () => {
        setCopyText(true);
        setAlertProps({ severity:'success', show: true, alertMessage: 'IP coppied!'})
    }
    let handleclose =() =>{
        <AlertComponent alertProps={alertProps}/>
        setCopyText(false)
    }
    return(
        <div>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="body2" component="h2">
                        {linkElement}
                    </Typography>
                    <Grid container justifyContent="space-between">
                        <Typography className={classes.attributes} color="textSecondary">
                            {instanceType}
                        </Typography>
                        <Typography className={classes.attributes} color="textSecondary">
                            {instanceID}
                        </Typography>
                        <CopyToClipboard text={privateIP}
                                         onCopy={handleCopy}>
                            <Typography className={classes.attributes} color="textSecondary" >
                                <ContentCopyIcon color={copyText ? "action" : "primary"} fontSize="small"/> <span>{privateIP}</span>
                                {copyText ?  <AlertComponent alertProps={alertProps}/> : null}{/*<span style={{color: 'red'}}>Copied.</span>*/}
                            </Typography>
                        </CopyToClipboard>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}