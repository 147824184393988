import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Box,
  Button,
  makeStyles,
  MuiThemeProvider,
  TextField,
  Typography,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import MUIRichTextEditor from "mui-rte";
import { convertToRaw } from "draft-js";
import { createAnnouncementEditorTheme } from "../theme";
import {axiosInstance} from "../api";
import UploadImagePopover from "./UploadImagePopover";
import { CloudUpload } from "@material-ui/icons";
import axios from "axios";
import {getIDTokenFromCookie} from "../authorization/js/auth-utils";
import { AlertComponent, PageLoader } from './presentational';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  centerText: {
    textAlign: "center",
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  formInput: {
    width: "300px",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  dateInput: {
    width: "160px",
    marginRight: theme.spacing(2),
  },
  bodyInput: {
    marginTop: theme.spacing(2),
  },
  buttonMargin: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  cardAnnouncement: {
    margin: "5%",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)",
  },
}));

const toLocal = (date) => {
  let local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON();
};

const expirDate = () => {
  let now = new Date();
  now.setDate(now.getDate() + 1);
  return now;
};

export default function CreateAnnouncement() {
  const history = useHistory();
  const classes = useStyles();
  const [msgExpiration, setMsgExpiration] = React.useState(expirDate());
  const [msgActivation, setMsgActivation] = React.useState(new Date());
  const [title, setTitle] = React.useState("");
  const [content, setContent] = React.useState({});
  const [empty, setEmpty] = React.useState("");
  const [anchor, setAnchor] = React.useState(null);
  // ref for rich text editor
  const ref = React.useRef(null);

  // formation validation state errors
  const [titleInputError, setTitleInputError] = React.useState(false);
  const [contentInputError, setContentInputError] = React.useState(false);
  const [expirationDateError, setExpirationDateError] = React.useState(false);
  const [activationDateError, setActivationDateError] = React.useState(false);
  let [alertProps, setAlertProps] = React.useState(null);
  let [pageLoaderProps, setPageLoaderProps] = React.useState(false);
  // empty states for resetting and defaulting editor
  const emptyState =
    '{"blocks":[{"key":"9tcuq","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}';

  // expiration date picker
  const handleExpirationChange = (date) => {
    setExpirationDateError(false);
    setMsgExpiration(date);
  };

  // activation date picker
  const handleActivationChange = (date) => {
    setActivationDateError(false);
    setMsgActivation(date);
  };

  // submit form
  const handleSubmit = () => {

    let dateLimit = new Date();
    dateLimit.setDate(dateLimit.getDate() + 10);

    // form validation
    if (msgExpiration <= msgActivation) {
      setExpirationDateError(true);
      return;
    }
    if (msgActivation > dateLimit) {
      setActivationDateError(true);
      return;
    }
    if (title === "") {
      setTitleInputError(true);
      return;
    }

    const raw = convertToRaw(content);
    if (raw.blocks.length === 1 && raw.blocks[0].text === "") {
      setContentInputError(true);
      return;
    }
    setPageLoaderProps(true)
    let obj = {
      title: title,
      msgExpiration: toLocal(msgExpiration).split(".")[0],
      msgActivation: toLocal(msgActivation).split(".")[0],
      msgCreated: toLocal(new Date()).split(".")[0],
      content: JSON.stringify(raw),
    };
    const idtoken = getIDTokenFromCookie();

    axiosInstance
      .post("/announcementswrite", obj,{headers: {"auth_id":idtoken}})
      .then((response) => {
          setAlertProps({ severity:'success', show: true, alertMessage: 'Announcement created successfully!'});
          // clear form
          setTitle("");
          setContent({});
          setEmpty(emptyState);
          setMsgActivation(new Date());
          setMsgExpiration(expirDate());
          //history.push('/');
      })
      .catch((error) => {
        setAlertProps({ show: true, alertMessage: 'Failed to create announcement. Try again later! '});
      }).finally(() => {
      // turn off page loading after api call.
      setPageLoaderProps(false);
    });
  };

  const handleFileUpload = (file) => {
    if (!ref.current) return null;
    return ref.current.insertAtomicBlockAsync(
      "IMAGE",
      uploadImage(file),
      "Uploading now..."
    );
  };

  const uploadImageToServer = (file) => {
    const idtoken = getIDTokenFromCookie();
    return new Promise(async (resolve) => {
      let obj = {
        objName: file.name,
        contentType: file.type,
      };
      let response = await axiosInstance.post("/getsignedurl", obj, {headers: {"auth_id":idtoken}});
      axios
        .put(response.data, file, {
          headers: {
            "Content-Type": file.type,
          },
        })
        .then((res) => {
          obj = {
            objNames: [file.name],
          };

          response = axiosInstance
            .post("/signedurlget", obj,  {headers: {'auth_id' : idtoken}})
            .then((res) => {
              resolve(res.data);
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    });
  };

  const uploadImage = (file) => {
    return new Promise(async (resolve, reject) => {
      const url = await uploadImageToServer(file);
      if (!url) {
        reject();
        return;
      }
      resolve({
        data: {
          url: url,
          type: "image", // or "video"
          width: 400,
          height: 300,
          alignment: "center", // or "center", "right"
        },
      });
    });
  };

  return (
      <>
        { pageLoaderProps && <PageLoader pageLoaderProps={pageLoaderProps} />}
        { alertProps && <AlertComponent alertProps={alertProps}/> }
        <Card variant="outlined" className={classes.cardAnnouncement}>
          <CardContent>
            <Grid container className={classes.marginTop} justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.centerText}>
                  Create Announcement
                </Typography>
              </Grid>

              {/* form input section */}
              <Grid container item xs={12} justifyContent="center">
                {/* title input */}
                <TextField
                    error={titleInputError ? true : false}
                    helperText={titleInputError ? "Title can't be blank!" : ""}
                    required
                    id="title"
                    label="Title"
                    placeholder="Enter message title"
                    value={title}
                    onChange={(e) => {
                      setTitleInputError(false);
                      setTitle(e.target.value);
                    }}
                    className={classes.formInput}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {/* activation date picker */}
                  <KeyboardDatePicker
                      error={activationDateError ? true : false}
                      helperText={
                        activationDateError
                            ? "Activation date can't be more than 10 days in the future"
                            : ""
                      }
                      disableToolbar
                      disablePast="true"
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="msgActivation"
                      label="Activation Date"
                      value={msgActivation}
                      onChange={handleActivationChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      className={classes.dateInput}
                  />

                  {/* expiration date picker */}
                  <KeyboardDatePicker
                      error={expirationDateError ? true : false}
                      helperText={
                        expirationDateError
                            ? "Expiration date must be after activation!"
                            : ""
                      }
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      margin="normal"
                      id="msgExpiration"
                      label="Expiration Date"
                      value={msgExpiration}
                      onChange={handleExpirationChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      className={classes.dateInput}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              {/* announcement message body */}
              <Grid
                  container
                  item
                  xs={10}
                  md={6}
                  justifyContent="center"
                  className={classes.marginTop}
              >
                {/* upload popover */}
                <UploadImagePopover
                    anchor={anchor}
                    onSubmit={(data, insert) => {
                      if (insert && data.file) {
                        handleFileUpload(data.file);
                      }
                      setAnchor(null);
                    }}
                />
                <MuiThemeProvider theme={createAnnouncementEditorTheme}>
                  <MUIRichTextEditor
                      error={contentInputError ? true : false}
                      ref={ref}
                      helperText={
                        contentInputError ? "Message contents can't be blank!" : ""
                      }
                      value={empty}
                      label="Type something here..."
                      controls={[
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                        "highlight",
                        "undo",
                        "redo",
                        "numberList",
                        "bulletList",
                        //"media",
                        "upload-image",
                      ]}
                      customControls={[
                        {
                          name: "upload-image",
                          icon: <CloudUpload />,
                          type: "callback",
                          onClick: (_editorState, _name, anchor) => {
                            setAnchor(anchor);
                          },
                        },
                      ]}
                      draftEditorProps={{
                        handleDroppedFiles: (_selectionState, files) => {
                          if (files.length && files[0].name !== undefined) {
                            handleFileUpload(files[0]);
                            return "handled";
                          }
                          return "not-handled";
                        },
                      }}
                      inlineToolbar={true}
                      onChange={(data) => {
                        setContentInputError(false);
                        setContent(data.getCurrentContent());
                      }}
                  />
                </MuiThemeProvider>
              </Grid>

            </Grid>
          </CardContent>
          <CardActions>
            <Grid className={classes.buttonMargin}   justifyContent = "center" item  container spacing={2}>
              <Grid item xs={2}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </Grid>
              <Grid  item xs={1}>
                <Button variant="contained" color="primary" onClick={()=>history.push("/")}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </>
  );
}
