import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Home } from "@material-ui/icons";
import {Grid} from "@material-ui/core";
import {hasAccess} from "../authorization/js/auth-utils"

const drawerWidth = 240;
// const primaryAws = "#FF9900";
// const secondaryAws = "#232f3e";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  hey: {
    fontSize: "20px",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function Navbar({ child }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const  hasUserAccess = hasAccess();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Grid container justifyContent="space-between">
            <Typography variant="h6" noWrap>
              Design Tech Engineering Central
            </Typography>
            <Typography variant="h6" noWrap id="username">
              username
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        onClose={() => handleDrawerClose()}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="body1">Navigation</Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {[
            {
              name: "Home",
              route: "/",
              icon: <Home style={{ color: "orange" }} />,
            },
            {
              name: "Factlab Reports",
              route: "/factlab",
              icon: (
                <img
                  src="/images/factlab.png"
                  alt=""
                  style={{ maxHeight: "24px", maxWidth: "24px" }}
                />
              ),
            },
            {
              name: "Usage Dashboard",
              route: "/dashboard",
              icon: (
                <img
                  src="/images/dashboard.png"
                  alt=""
                  style={{ maxHeight: "24px", maxWidth: "24px" }}
                />
              ),
            },
             {
              name: "Windchill",
              route: "/windchill",
              icon: (
                <img
                  src="/images/windchillIcon.png"
                  alt=""
                  style={{ maxHeight: "24px", maxWidth: "24px" }}
                />
              ),
            },
           /* {
              name: "RI Dashboard [DEMO]",
              route: "/ri",
              icon: (
                <img
                  src="/images/ec2Icon.png"
                  alt=""
                  style={{ maxHeight: "24px", maxWidth: "24px" }}
                />
              ),
            },*/
          ].map((text, index) => (

            <Link
              to={text.route}
              style={{ textDecoration: "none", color: "black" }}
              key={index}
            >
              { !hasUserAccess &&  text.name === "Windchill" ?"": <ListItem button key={text.name}>
                <ListItemIcon>{text.icon}</ListItemIcon>
                <ListItemText primary={text.name} />
              </ListItem> }
            </Link>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {/* content goes here to get push over effect */}
        {child}
      </main>
    </div>
  );
}
