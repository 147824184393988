import React, {useEffect, useState} from "react";
import { Form, Field } from "react-final-form";
import {
    Typography,
    Paper,
    Grid,
    Button,
    CssBaseline,
    Card,
    makeStyles
} from "@material-ui/core";
//import axios from "axios";
import {getUserEmail, getIDTokenFromCookie} from "../authorization/js/auth-utils";
import {wocAxiosInstance} from "../api";
import { useHistory } from "react-router-dom";
import { AlertComponent, PageLoader } from './presentational';
const useStyles = makeStyles((theme) => ({
    cardstyle: {
        margin: "5%",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)",
    },
}));
export default function TransferWindchillInstanceForm() {
    //const [message, setMessage] = useState('');
    const [data, setData] = useState({});
    const history = useHistory();
    const classes = useStyles();
    let [alertProps, setAlertProps] = React.useState(null);
    let [pageLoaderProps, setPageLoaderProps] = React.useState(false);

    useEffect(() =>  {
        setData({"dnsName": "", "newOwner": ""});
        let data = localStorage.getItem('data');
        data = JSON.parse(data);
        console.log("dnsname:"+ data["instanceids"])
        const dnsName = data["dnsName"];
        if(dnsName !== undefined){
            setData({"dnsName": dnsName, "newOwner": "", "data":data});
        }
    }, [])

    const onSubmit = async (values,form) => {
        setPageLoaderProps(true)
        console.log("values");
        console.log(values);
        data.newOwner = (values.newOwner).toLowerCase();
        console.log(data)
        let idtoken = getIDTokenFromCookie();
       await wocAxiosInstance.post('/windchill/transfer', data ,{headers: {'auth_id' : idtoken}})
            .then(function (response) {
                setAlertProps({ severity:'success', show: true, alertMessage: 'Request to transfer windchill instance is submitted successfully! Please check back in 10 minutes.'});

            })
            .catch(function (error) {
                console.log(error);
                setAlertProps({ show: true, alertMessage: 'Failed to transfer windchill instance'});
            }).finally(() => {
               setPageLoaderProps(false);
            // turn off page loading after api call.
              // history.push("/windchill")
        });

        // clear form
        Object.keys(values).forEach(key => {
            form.change(key, undefined);
            form.resetFieldState(key);
        });
    };

    const validate = (values) => {
        const errors = {};
        if (!values.dnsName) {
            errors.dnsName = "Required";
        }
        if (!values.newOwner) {
            errors.newOwner = "Required";
        }
        return errors;
    };

    const formReset = () => {
        setData({});
    }

    const formFields = [
        {
            size: 12,
            field: (
                <div>
                    <Grid container spacing={2} style={{marginTop:10}}>
                        <Grid item  xs={7}>
                            <label style={{color:"blue", paddingLeft:50}}>DNS Name </label>
                        </Grid>
                        <Grid item  xs={5}>
                            <Field
                                name="dnsName"
                                margin="none"
                                required={true}
                                readOnly={true}
                                component="input"
                                placeholder="DNS Name"
                            />
                        </Grid>
                    </Grid>
                </div>
            ),
        },
        {
            size: 12,
            field: (
                <div>
                    <Grid container spacing={2} >
                        <Grid item xs={7}>
                            <label style={{color:"blue", paddingLeft:50}}>New Owner </label>
                        </Grid>
                        <Grid item xs={5} >
                            <Field
                                name="newOwner"
                                margin="none"
                                required={true}
                                component="input"
                                placeholder="New Owner"
                            />
                        </Grid>
                    </Grid>
                </div>
            ),
        },
    ];

    return (
        <>
            { pageLoaderProps && <PageLoader pageLoaderProps={pageLoaderProps} />}
            { alertProps && <AlertComponent alertProps={alertProps}/> }
        <Card variant="outlined" className={classes.cardstyle}>
        <div style={{ padding: 16, margin: "auto", maxWidth: 600 }}>
            <CssBaseline />
            <Typography variant="h4" align="center" component="h1" gutterBottom>
                <img
                    src="/images/windchillIcon.png"
                    alt=""
                    style={{ maxHeight: "24px", maxWidth: "24px" }}
                />{" "}
                Transfer Windchill Instance
            </Typography>
            <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={data}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Paper style={{ padding: 16 }}>
                            <Grid container alignItems="flex-start" spacing={2}>
                                {formFields.map((item, idx) => (
                                    <Grid item xs={item.size} key={idx}>
                                        {item.field}
                                    </Grid>
                                ))}
                                <Grid item style={{ marginTop: 16, textAlign: "center" }}  container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={submitting}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={()=> history.push("/windchill")}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                        <pre></pre>
                    </form>
                )}
            />
        </div>
        </Card>
            </>
    );
}
