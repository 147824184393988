import React, {useEffect, useState} from "react";
import { Form, Field } from "react-final-form";
import {
  Typography,
  Paper,
  Grid,
  Button,
  CssBaseline,
    Card,
    makeStyles
} from "@material-ui/core";
//import axios from "axios";
import {getUserEmail, getIDTokenFromCookie} from "../authorization/js/auth-utils";
import {wocAxiosInstance} from "../api";
import { AlertComponent, PageLoader } from './presentational';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    cardstyle: {
        margin: "5%",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)",
    },
}));
export default function DeleteWindchillInstanceForm(props) {
    const history = useHistory();
  const [message, setMessage] = useState('');
  const [data, setData] = useState({});
  const idtoken = getIDTokenFromCookie();
  const classes = useStyles();
  let [alertProps, setAlertProps] = useState(null);
  let [pageLoaderProps, setPageLoaderProps] = useState(false);

  useEffect(() =>  {
    const email = getUserEmail();
    setData({"dnsName": "", "email": email});
    const dnsName = localStorage.getItem('dnsName');
    if(dnsName !== undefined){
        setData({"dnsName": dnsName, "email": email});
    }
  }, [])

  const onSubmit = async (values,form) => {
    console.log(values)
      setPageLoaderProps(true)
    const payload = JSON.stringify(values, 0, 2);

    const idtoken = getIDTokenFromCookie();
    let deleteResponse = "";
    let obj = {
          data: JSON.parse(payload)
    };
    let request_id =""
    await wocAxiosInstance.post('/windchill/remove', obj, {headers: {'auth_id' : idtoken}})
        .then(function (response) {
            setPageLoaderProps(false);
            deleteResponse = response;
            request_id = deleteResponse.data.request_id;
            setTimeout(setAlertProps({ severity:'success', show: true, alertMessage: 'Request to delete windchill instance is submitted successfully. Please check back in 15 minutes!'}),5000);
            //history.push('/windchill');
      })
          .catch(function (error) {
              console.log(error);
              setAlertProps({ show: true, alertMessage: 'Failed to submit request to delete windchill instance. Try again later or contact DT Admin team! '});
          }).finally(() => {
            // turn off page loading after api call.
            setPageLoaderProps(false);
        });;
      let data = {
          requestID: request_id
      }
/*      const creationStatusResponse= await wocAxiosInstance.post('/windchill/status', data, {headers: {'auth_id' : idtoken}})
          .then(function (response) {
              setMessage(`REQUEST_ID: ${request_id} \
            Creation Status: ${creationStatusResponse.data}`);
          });*/

      // clear form
      Object.keys(values).forEach(key => {
          form.change(key, undefined);
          form.resetFieldState(key);
      });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.dnsName) {
      errors.dnsName = "Required";
    }
    if (!values.email) {
      errors.email = "Required";
    }
    return errors;
  };

  const formReset = () => {
    setData({});
  }

  const formFields = [
    {
      size: 12,
      field: (
          <div>
              <Grid container spacing={2}>
                  <Grid item  xs={5}>
                      <label style={{color:"blue"}}>DNS Name </label>
                  </Grid>
                  <Grid item  xs={7}>
                    <Field
                        name="dnsName"
                        margin="none"
                        required={true}
                        readOnly={true}
                        component="input"
                        placeholder="DNS Name"
                    />
                  </Grid>
              </Grid>
          </div>
      ),
    },
    {
      size: 12,
      field: (
          <div>
              <Grid container spacing={2}>
                  <Grid item  xs={5}>
                      <label style={{color:"blue"}}>Email </label>
                  </Grid>
                  <Grid item  xs={7}>
                    <Field
                        type="email"
                        name="email"
                        margin="none"
                        required={true}
                        component="input"
                        placeholder="Email"
                    />
                  </Grid>
              </Grid>
          </div>
      ),
    },
  ];

  return (
      <>
          { pageLoaderProps && <PageLoader pageLoaderProps={pageLoaderProps} />}
          { alertProps && <AlertComponent alertProps={alertProps}/> }
      <Card variant="outlined" className={classes.cardstyle}>
        <div style={{ padding: 16, margin: "auto", maxWidth: 600 }}>
          <CssBaseline />
          <Typography variant="h4" align="center" component="h1" gutterBottom>
            <img
              src="/images/windchillIcon.png"
              alt=""
              style={{ maxHeight: "24px", maxWidth: "24px" }}
            />{" "}
            Delete Windchill Instance
          </Typography>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={data}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Paper style={{ padding: 16 }}>
                  <Grid container alignItems="flex-start" spacing={2}>
                    {formFields.map((item, idx) => (
                      <Grid item xs={item.size} key={idx}>
                        {item.field}
                      </Grid>
                    ))}
                      <Grid item style={{ marginTop: 16, textAlign: "center" }}  container spacing={2}>
                          <Grid item xs={6}>
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={submitting}
                              >
                                Submit
                              </Button>
                            </Grid>
                          <Grid item xs={6}>
                              <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={()=> history.push("/windchill")}
                              >
                                  Cancel
                              </Button>
                          </Grid>
                      </Grid>
                  </Grid>
                </Paper>
                <pre></pre>
              </form>
            )}
          />
        </div>
      </Card>
          </>
  );
}
